@use "sass:map";

.pagination{
    .page-item{
        .page-link{
            border: none;
            color: $body-color;

            &:hover{
                background: none;
            }

            &:focus{
                box-shadow: none;
            }
        }
    }

    .page-item.active{
        .page-link{
            border-radius: 8px;
            color: #ffffff;
            padding: 6px 12px;
            font-weight: 700;

            &:hover{
                background: #da4200;
            }
        }
    }

    .link-previous.disabled{
        .link-previous-link{
            background: none;
            color: map.get($custom-theme-color, "grey-300");

            &:hover{
                cursor: default;
            }
        }
    }

    .link-next.disabled{
        .link-next-link{
            background: none;
            color: map.get($custom-theme-color, "grey-300");

            &:hover{
                cursor: default;
            }
        }
    }

    .link-previous{
        .link-previous-link{
            padding: 4px 8px;
            margin-right: 24px;
            text-decoration: none;
            color: $body-color;
            display: block;

            &:focus{
                box-shadow: none;
            }
        }
    }

    .link-next{
        .link-next-link{
            padding: 4px 8px;
            margin-left: 24px;
            text-decoration: none;
            color: $body-color;
            display: block;

            &:focus{
                box-shadow: none;
            }
        }
    }
}