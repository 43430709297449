//Main variables
$font-family-sans-serif: "Plus Jakarta Sans", sans-serif;
$font-size-base: 1rem; // 16px;
$body-color: #575757;

//Grid variables
// $grid-columns: 16;
// $grid-gutter-width: 1.25rem;
// $grid-breakpoints: (
//     xs: 0,
//     sm: 576px,
//     md: 768px,
//     lg: 992px,
//     xl: 1200px
// );
// $container-max-widths: (
//   sm: 540px,
//   md: 720px,
//   lg: 960px,
//   xl: 1100px
// );

// Colors
$primary: #E24400;
$dark: #242424;
// $light: #FFF0F2;
$success: #7FBC3B;

//Headings variables
$headings-font-weight: 600;
$headings-color: #242424;
$h1-font-size: 3rem;
$h2-font-size: 2rem;
$h3-font-size: 1.75rem;
$h4-font-size: 1.5rem;
$h5-font-size: 1.25rem;
$h6-font-size: 1rem;

//Buttons variables
$btn-font-size: 1rem;
$btn-font-weight: 600;
$btn-padding-y: .5rem;
$btn-padding-x: 16px;
$btn-line-height: 1.5rem;
$btn-border-radius: .5rem;

// $btn-padding-y-lg: 20px;
// $btn-padding-x-lg: 20px;
// $btn-font-size-lg: 1rem;

// $btn-padding-y-sm: .625rem;
// $btn-padding-x-sm: 1rem;
// $btn-font-size-sm: .8125rem;
// $btn-border-radius-sm: .5rem;

// Inputs variables
// $input-padding-y: .75rem;
// $input-padding-x: 1.5rem;
// $input-font-size: .9375rem;
// $input-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
// $input-border-radius: 10px;
// $input-focus-border-color: #960000;
// $input-border-width: 0;

// Select variables
// $form-select-padding-y: 0.75rem;
// $form-select-padding-x: 1.5rem;
// $form-select-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
// $form-select-border-radius: 10px;

//Navbar variables
// $nav-link-font-size: 0.875rem;
// $navbar-padding-y: 0;
// $navbar-brand-padding-y: 0rem;

//Tables variables
// $table-cell-padding-y: 12px;
// $table-cell-padding-x: 8px;
// $border-color: #F3F3F3;
// $table-cell-vertical-align: middle;
// $table-border-width: 0.5px;
// $table-th-font-weight: 500;

// Checkboxes
// $form-check-input-width: 20px;
// $form-check-padding-start: 10px;
// $form-check-input-border: 2px solid $primary;

// Badges
// $badge-padding-y: .25rem;
// $badge-padding-x: 1rem;
// $badge-border-radius: 6.25rem;
// $badge-font-size: .9375rem;
// $badge-font-weight: 400;
// $badge-color: #ffffff;

// Border
$border-widths: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px,
  6: 6px,
  8: 8px
);

// Modal
// $modal-backdrop-bg: #ffffff;
// $modal-backdrop-opacity: 1;
// $modal-md: 540px;
// $modal-content-border-width: 0;
// $modal-content-border-radius: 24px;
// $modal-inner-padding: 32px;

// Spacing
$spacer: 1rem;
$spacers: (
  0: 0,
  2: .125rem,
  4: .25rem,
  5: .3125rem,
  8: .5rem,
  10: $spacer * .625,
  12: .75rem,
  16: 1rem,
  20: 1.25rem,
  24: 1.5rem,
  26: 1.625rem,
  28: 1.75rem,
  32: 2rem,
  36: 2.25rem,
  40: 2.5rem,
  44: 2.75rem,
  48: 3rem,
  52: 3.25rem,
  56: 3.5rem,
  60: 3.75rem,
  64: 4rem,
  68: 4.25rem,
  70: 4.375rem,
  72: 4.5rem,
  76: 4.75rem,
  80: 5rem,
  84: 5.25rem,
  88: 5.5rem,
  96: 6rem,
  100: 6.25rem,
  110: 6.875rem,
  116: 7.25rem,
  120: 6.25rem,
  126: 7.875rem,
  256: 16rem,
  m8: -0.5rem,
  m180: -11.25rem
);

// Font
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  8: .5rem,
  12: .75rem,
  14: .875rem,
  16: 1rem,
  20: 1.25rem, 
  24: 1.5rem,
  28: 28px
);

// $font-weight-light: 300;
$font-weight-normal: 400;
// $font-weight-semibold: 600;
$font-weight-bold: 700;

// Import Bootstrap 5
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";

// Colors
$custom-theme-color: (
  "primarylight": #E3F5D9,
  "primary-400": #F56629,
  "grey-100": #EDEDED,
  "grey-300": #A3A3A3,
  "grey-400": #7D7D7D,
  "grey-500": #575757,
  "grey-900": #242424
);
$theme-colors: map-merge($custom-theme-color, $theme-colors);

@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";

// Position values for top, left, right, bottom
$position-values: (
  0: 0,
  50: 50%,
  100: 100%,
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  8: 8px,
  12: 12px,
  13: 13px,
  16: 16px,
  18: 18px,
  24: 24px,
  26: 26px,
  82: 82px,
  102: 102px,
  114: 114px,
  144: 144px,
  168: 168px,
  m1: -1px,
  m3: -3px,
  m2: -2px,
  m4: -4px,
  m6: -6px,
  m8: -8px,
  m22: -22px,
  m32: -32px,
  m56: -56px,
  m70: -70px
);

// Shadows
$box-shadow-lg: 7px 16px 20px rgba(0, 0, 0, 0.03);

@import "~bootstrap/scss/utilities";

// Utilities
$utilities: map-merge(
  $utilities, 
  (
    "width": (
      property: width,
      class: w,
      responsive: true,
      values: (
        25: 25%,
        33: 33.333333%,
        50: 50%,
        75: 75%,
        80: 80%,
        100: 100%,
        auto: auto,
        1: 1px,
        4: 4px,
        8: 8px,
        12: 12px,
        13: 13px,
        16: 16px,
        18: 18px,
        20: 20px,
        22: 22px,
        24: 24px,
        28: 28px,
        32: 32px,
        36: 36px,
        40: 40px,
        44: 44px,
        47: 47px,
        48: 48px,
        52: 52px,
        53: 53px,
        56: 56px,
        60: 60px,
        62: 62px,
        64: 64px,
        68: 68px,
        72: 72px,
        76: 76px,
        88: 88px,
        104: 104px,
        120: 120px, 
        140: 140px, 
        180: 180px,
        188: 188px,
        200: 200px,
        220: 220px,
        232: 232px, 
        300: 300px,
        344: 344px
      )
    ),
    "height": (
      property: height,
      class: h,
      responsive: true,
      values: (
        25: 25%,
        33: 33.33333333%,
        50: 50%,
        75: 75%,
        100: 100%,
        auto: auto,
        1: 1px,
        4: 4px,
        8: 8px,
        12: 12px,
        13: 13px,
        16: 16px,
        18: 18px,
        20: 20px,
        22: 22px,
        24: 24px,
        28: 28px,
        32: 32px,
        36: 36px,
        40: 40px,
        42: 42px,
        44: 44px,
        48: 48px,
        52: 52px,
        53: 53px,
        56: 56px,
        58: 58px,
        60: 60px,
        64: 64px,
        68: 68px,
        72: 72px,
        76: 76px,
        80: 80px,
        84: 84px,
        88: 88px,
        96: 96px,
        104: 104px,
        112: 112px, 
        140: 140px,
        160: 160px,
        180: 180px,
        200: 200px, 
        232: 232px, 
        220: 220px,
        240: 240px
      )
    ),
    "min-height": (
      property: min-height,
      class: minh,
      values: (
        62: 62px,
        413: 413px,
        180: 180px,
        225: 225px,
        230: 230px,
        initial: initial
      )
    ),
    "max-height": (
      property: max-height,
      class: maxh,
      values: (
        100: 100%,
        320: 320px,
        560: 560px
      )
    ),
    "min-width": (
      property: min-width,
      class: minw,
      values: (
        initial: initial,
        53: 53px,
        62: 62px,
        124: 124px,
        auto: auto
      )
    ),
    "max-width": (
      property: max-width,
      class: maxw,
      values: (
        100: 100%,
        320: 320px,
        380: 380px
      )
    ),
    "flex-basis": (
      property: flex-basis,
      class: flex-basis,
      responsive: true,
      values: (
        15: 15%,
        20: 20%,
        44: 44%,
        50: 50%,
        100: 100%,
        33: 33.33333%,
        90: 90px,
        auto: auto
      )
    ),
    "flex-grow": (
      responsive: true,
      property: flex-grow,
      class: flex,
      values: (
        grow-0: 0,
        grow-1: 1,
        grow-initial: initial
      )
    ),
    "font-size": (
      rfs: false,
      property: font-size,
      class: fs,
      responsive: true,
      values: $font-sizes
    ),
    "z-index": (
      property: z-index,
      class: z-index,
      responsive: true,
      values: (
        5: 5,
        10: 10,
        15: 15,
        50: 50
      )
    ),
    "rounded": (
      property: border-radius,
      class: rounded,
      values: (
        null: var(--#{$prefix}border-radius),
        0: 0,
        1: var(--#{$prefix}border-radius-sm),
        2: var(--#{$prefix}border-radius),
        3: var(--#{$prefix}border-radius-lg),
        4: var(--#{$prefix}border-radius-xl),
        5: var(--#{$prefix}border-radius-2xl),
        7: 7px,
        8: 8px,
        12: 12px,
        16: 16px,
        17: 17px,
        24: 24px,
        32: 32px,
        25: 25px,
        75: 75px,
        20: 20px,
        circle: 50%,
        pill: var(--#{$prefix}border-radius-pill)
      )
    ),
    "rounded-bottom": (
      property: border-bottom-right-radius border-bottom-left-radius,
      class: rounded-bottom,
      values: (
        null: var(--#{$prefix}border-radius),
        8: 8px
      )
    ),
    "shadow": (
      property: box-shadow,
      class: shadow,
      values: (
        null: $box-shadow,
        sm: $box-shadow-sm,
        lg: $box-shadow-lg,
        blue: 0 4px 8px rgba(0, 83, 255, 0.5),
        primary: 0 4px 8px rgba(150, 0, 0, 0.5),
        grey: -1px 2px 4px rgba(0,0,0,0.05),
        none: none,
      )
    ),
    "border-bottom": (
      property: border-bottom, 
      responsive: true, 
      values: (
        null: var(--#{$prefix}border-width) var(--#{$prefix}border-style) var(--#{$prefix}border-color),
        0: 0,
        1: 1px var(--#{$prefix}border-style),
        2: 2px var(--#{$prefix}border-style)
      )
    ),
    "border-top": (
      property: border-top,
      values: (
        null: var(--#{$prefix}border-width) var(--#{$prefix}border-style) var(--#{$prefix}border-color),
        0: 0,
        1: 1px var(--#{$prefix}border-style),
        2: 2px var(--#{$prefix}border-style)
      )
    ),
    "background-size": (
      property: background-size,
      responsive: true,
      class: background-size,
      values: (
        100: 100% auto,
        cover: cover,
        auto: auto
      )
    ),
    "rounded-start": (
      property: border-bottom-left-radius border-top-left-radius,
      class: rounded-start,
      values: (
        null: var(--#{$prefix}border-radius),
        7: 7px
      )
    ),
    "background-repeat": (
      property: background-repeat,
      class: background-repeat,
      values: (
        "no-repeat": no-repeat
      )
    ),
    "background-position": (
      property: background-position,
      class: background-position,
      values: (
        "top-center": top center
      )
    ),
    "rounded-top": (
      property: border-top-left-radius border-top-right-radius,
      class: rounded-top,
      values: (
        null: var(--#{$prefix}border-radius),
        20: 20px
      )
    ),
    "box-sizing": (
      property: box-sizing,
      class: box-sizing,
      values: (
        content-box: content-box
      )
    )
  )
);

// Import Bootstrap 5
@import "~bootstrap/scss/bootstrap.scss";

// Import Bootstrap Icons
@import "~bootstrap-icons/font/bootstrap-icons.scss";

// Import react-image-gallery styles
@import "~react-image-gallery/styles/scss/image-gallery.scss";

// Import
@import "styles/fonts.scss";
@import "styles/general.scss";
@import "styles/pagination.scss";
@import "styles/navbar.scss";
@import "styles/breadcrumb";


// Theme styles
body{
    height: 100vh;

    .list-no-style{
      list-style-type: none;
      padding: 0;
      margin: 0;

      li{
        padding: 0;

        &:before{
          display: none;
        }
      }
    }

    .list-normal{
      list-style: none;
      padding: 0;
      margin: 0;
    
      li{
        padding-left: 24px;
        position: relative;
    
        &:before{
          content: "";
          width: 8px;
          height: 8px;
          background: #E24400;
          border-radius: 100%;
          display: block;
          position: absolute;
          left: 4px;
          top: 8px;
        }
      }
    }

    #root{
        height: 100vh;
        display: flex;
        flex-direction: column;
    }
}

.link-normal{
  text-decoration: none;
  position: relative;
  font-weight: 600;

  &:hover{
    color: #BD3900;

    &:after{
      background: #BD3900;
    }
  }

  &:after{
    content: "";
    background: $primary;
    height: 2px;
    width: 100%;
    position: absolute;
    bottom: -2px;
    left: 0;
  }

  i{
    font-size: 20px;
    position: relative;
    top: 2px;
    line-height: 24px;
  }
}

select{
  &:invalid{
    color: red;
  }
}

.background-none{
  background: none;
}

.form-section{
  border-bottom: 1px solid #EDEDED;
  padding-bottom: 32px;
  margin-bottom: 32px;

  &:last-child{
    border-bottom: none;
  }
}

.status{
  &-danger{
    background: #ffdada;
    color: #c91010;
    padding: 4px 8px;
    border-radius: 8px;
    font-size: 14px;
    display: inline-block;
  }

  &-success{
    background: #b2f0c1;
    color: #0a7f45;
    padding: 4px 8px;
    border-radius: 8px;
    font-size: 14px;
    display: inline-block;
  }

  &-checking{
    background: #ffe3d9;
    color: #d53c03;
    padding: 4px 8px;
    border-radius: 8px;
    font-size: 14px;
    display: inline-block;
  }
}

.nav.nav-pills{
  .nav-link.active{
    color: #fff;
    font-weight: 600;
  }

  .nav-link{
    color: #626568;
    border-radius: 8px;
  }
}

.footer-nav{
  h5{
    margin-bottom: 16px;
  }

  .nav{
    padding: 0;
    margin: 0;

    .nav-item{
      margin: 0 0 8px !important;
      padding: 0;

      &:before{
        display: none;
      }

      a{
        color: grey;
        text-decoration: none;
      }
    }
  }
}

.announcement_number{
  position: fixed;
  background: white;
  bottom: 0;
  left: 0;
  box-shadow: 0 0 4px rgba(0,0,0,0.1);
  padding: 8px 12px;
  width: 100%;

  @include media-breakpoint-up(md){
    display: none;
  }
}

.announcement-description{
  border-bottom: 2px solid #EDEDED;

  @include media-breakpoint-up(md){
    border-bottom: none;
  }
}