.ql-container{
    font-size: inherit !important;
    font-family: inherit !important;

    .ql-editor{
        height: 20rem;
        line-height: inherit;

        ul{
            padding-left: 1em;
        }

        ol{
            padding-left: 1em;
        }
    }
}