.dropzone-delete{
    position: absolute;
    top: -8px;
    right: -8px;
    z-index: 5;
    background: white;
    border-radius: 8px;
    padding: 4px;

    &:hover{
        cursor: pointer;
    }

    img{
        width: 20px;
        height: auto;
    }
}