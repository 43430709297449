// Heading
h1{

}

h2{

}

h3{
    margin-bottom: 24px;
}

.h4{
  margin-bottom: 16px;
}

h5{
  margin-bottom: 16px;
}

// Buttons
.btn{
    &-lg{
      padding: 12px 16px;
      font-size: 18px;
    }

    > i{
        margin-right: 8px;
        font-size: 24px;
        position: relative;
        top: 2px;
    }

    &-primary{
      color: #fff;

      &:hover{
        color: #ffffff;
      }

      &:active, &:focus-visible{
        color: #ffffff !important;
      }

      &:disabled{
        color: #ffffff;
      }
    }

    &-secondary{
      background: none;
      border: 2px solid $primary;
      color: $primary;

      &:hover{
        color: #F56629;
        background: none;
        border: 2px solid #F56629;
      }
    }
  }