.breadcrumb{
    .breadcrumb-item{
        a{
            text-decoration: none;
            color: #7D7D7D;
            transition: color 0.3s ease-in-out;

            &:hover{
                color: #A3A3A3;
            }
        }
    }
}