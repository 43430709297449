.navbar{
    .navbar-brand{
        margin-right: 40px;
    }

    .navbar-nav{
        .dropdown{
            .dropdown-menu{
                margin-bottom: 24px;

                .dropdown-item{
                    padding: 8px 16px;
                }
            }
        }

        .nav-item{
            margin-bottom: 8px;

            @include media-breakpoint-up(md){
                margin-bottom: 0;
            }

            > .btn{
                width: 100%;
            }
        }
    }
}