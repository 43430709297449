@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('../assets/fonts/PlusJakartaSans-Regular.woff2') format('woff2'),
         url('../assets/fonts/PlusJakartaSans-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('../assets/fonts/PlusJakartaSans-Italic.woff2') format('woff2'),
         url('../assets/fonts/PlusJakartaSans-Italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('../assets/fonts/PlusJakartaSans-Bold.woff2') format('woff2'),
         url('../assets/fonts/PlusJakartaSans-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('../assets/fonts/PlusJakartaSans-BoldItalic.woff2') format('woff2'),
         url('../assets/fonts/PlusJakartaSans-BoldItalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}